export const GBExperiments = {
  admissionProgressWithStepper: {
    feature: 'admission-progress-with-stepper',
  },
  basicModuleGifts: {
    feature: 'basic-module-gifts',
  },
  socialProofSection: {
    feature: 'social-proof-section',
  },
  webinarNewTimer: {
    feature: 'webinar-new-timer',
  },
  professionCardRedirectToCoursePl: {
    feature: 'profession-card-redirect-to-course-pl',
  },
  professionCardRedirectToCourseUa: {
    feature: 'profession-card-redirect-to-course-ua',
  },
  subscriptionProductSignUpFlow: {
    feature: 'subscription-product-sign-up-flow',
  },
  subscriptionProductCourseContentLP: {
    feature: 'course-content-lp',
  },
  subscriptionProductSkillBasedLp: {
    feature: 'skill-based-lp',
  },
  redesignCoverSectionFTUA: {
    feature: 'redesign-cover-section-ft-ua',
  },
  redirectToFirstTopicAfterRegistration: {
    feature: 'redirect-to-first-topic-after-registration',
  },
  shortQuiz: {
    feature: 'short-quiz',
  },
  improvedTestResults: {
    feature: 'improved-test-results',
  },
  ftSurveySignUp: {
    feature: 'ft-survey-sign-up',
  },
  noJobGuaranteeTagUA: {
    feature: 'no-job-guarantee-tag-ua',
  },
  testUserDistributionWithoutNamespaces: {
    feature: 'test-user-distribution-without-namespaces',
  },
  platformMonetaryBonus: {
    feature: 'platform-monetary-bonus-test',
  },
  checkoutRegistraion: {
    feature: 'stripe-checkout-registration',
  },
  mergedCoursesParttime: {
    feature: 'merged-courses-parttime',
  },
  extendedTypeformPl: {
    feature: 'extended-typeform-pl',
  },
  professionCardTags: {
    feature: 'profession-card-tags',
  },
  webflowQuizVersionsSplit: {
    feature: 'webflow-quiz-versions-split',
  },
  redesignCoursePage: {
    feature: 'redesign-course-page',
  },
  ongoingTypeform: {
    feature: 'ongoing-typeform',
  },
};

export const GBFeatures = {
  monetaryBonusSection: {
    name: 'monetary-bonus-section',
    defaultValue: false,
  },
};

export enum GBExperimentVariants {
  Disabled = -1,
  Control = 0,
  Variant1 = 1,
  Variant2 = 2,
  Variant3 = 3,
}
